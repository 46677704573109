const EXPAND_DASHBOARD_NAV = 'EXPAND_DASHBOARD_NAV';
const COLLAPSE_DASHBOARD_NAV = 'COLLAPSE_DASHBOARD_NAV';
const TOGGLE_DASHBOARD_NAV = 'TOGGLE_DASHBOARD_NAV';


/*
	This reducer is for generic app-wide ui state that for some reason can't be managed by react useState
*/


const initialState = {
	isDashboardNavCollapsed: false,
};


export const toggleCollapseDashboardNav = () => (dispatch, getState) => {
	let state = getState();
	let isCollapsed = state.uiState.isDashboardNavCollapsed;
	return dispatch({
		type: TOGGLE_DASHBOARD_NAV,
		payload: {
			isDashboardNavCollapsed: !isCollapsed,
		}
	});
};

export const expandDashboardNav = () => (dispatch) => {
	return dispatch({
		type: EXPAND_DASHBOARD_NAV,
		payload: {
			isDashboardNavCollapsed: false,
		}
	});
};

export const collapseDashboardNav = () => (dispatch) => {
	return dispatch({
		type: COLLAPSE_DASHBOARD_NAV,
		payload: {
			isDashboardNavCollapsed: true,
		}
	});
};

const uiStateReducer = (state = initialState, action) => {
	switch (action.type) {
		case EXPAND_DASHBOARD_NAV:
		case COLLAPSE_DASHBOARD_NAV:
		case TOGGLE_DASHBOARD_NAV:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default uiStateReducer;
