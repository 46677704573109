import './Paginator.css';

function PageButton({ pageNum, selectedPage, onClick}) {
	return (
		<li key={pageNum} className={`page-item ${selectedPage === pageNum ? 'active' : ''}`}>
			<span className="page-link" onClick={() => onClick(pageNum)}>{pageNum}</span>
		</li>
	);
}

function Paginator(props) {
	let {
		currentPage,
		totalPages,
		setPage,
	} = props;

	let hasNextPage = currentPage < totalPages;
	let hasPreviousPage = currentPage > 1;

	function getNextPage() {
		if (hasNextPage) {
			setPage(currentPage + 1);
		}
	}

	function getPreviousPage() {
		if (currentPage > 1) {
			setPage(currentPage - 1);
		}
	}

	//logic for hiding pages when there are a lot of them
	const MAX_UNHIDDEN_PAGES = 11; //if less pages than this, just show them all
	const PAGES_AT_BEGINNING_END = 3; //always show first 3 and last 3
	const PAGES_AROUND_CURRENT_PAGE = 2; //always show 2 pages before and after current selected page
	const lotsOfPages = () => totalPages > MAX_UNHIDDEN_PAGES;
	const isBeginningOrEnd = (i) => (i <= PAGES_AT_BEGINNING_END || i > totalPages - PAGES_AT_BEGINNING_END);
	const nearCurrentPage = (i) => (Math.abs(i - currentPage) <= PAGES_AROUND_CURRENT_PAGE);
	const shouldSkipPage = (i) => (lotsOfPages() && !isBeginningOrEnd(i) && !nearCurrentPage(i));

	let pageButtons = [];
	let hasContinued = false;
	for (let i = 1; i <= totalPages; i++) {
		if (shouldSkipPage(i)) {
			//when skipping pages, insert a `...` once for each contiguous block of skipped pages
			if (!hasContinued) {
				pageButtons.push(<li key={i} className="page-item page-ellipsis">...</li>);	
			}
			hasContinued = true;
			continue;
		}
		hasContinued = false;
		pageButtons.push(
			<PageButton pageNum={i} onClick={setPage} key={i} selectedPage={currentPage} />
		);
	}

	return (
		<nav className="paginator">
			<ul className="pagination">
				<li className={`page-item ${hasPreviousPage ? '' : 'disabled'}`}>
					<span className="page-link" onClick={() => getPreviousPage()}><i className="bi bi-caret-left-fill" /></span>
				</li>
				{pageButtons}
				<li className={`page-item ${hasNextPage ? '' : 'disabled'}`}>
					<span className="page-link" onClick={() => getNextPage()}><i className="bi bi-caret-right-fill" /></span>
				</li>
			</ul>
		</nav>
	);
}

export default Paginator;
