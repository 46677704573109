import AddressSearch from 'components/addressSearch/AddressSearch';
import { useSelector } from 'react-redux';
import { usePageTitle } from 'utils/pageTitle';

import './PropertyNotFound.css';


const PropertyNotFound = (props) => {
	usePageTitle('Not Found');
	const {
		query,
	} = useSelector(state => ({
		query: state.router.location?.query,
	}));
	let address = decodeURIComponent(query?.address);

	return (
		<div className="property-not-found-page">
			<div className="property-not-found-page-content">
				<h1 className="text-center"><i className="bi bi-emoji-frown" /></h1>
				{query?.address ? (
					<div>
						<h4 className="text-center">We couldn't find</h4>
						<h2 className="text-center">{address}</h2>
					</div>
					) : (
					<h2 className="text-center">We couldn't find the property you're looking for</h2>
				)}
				<p className="text-center">Try searching for another property.</p>

				<div className="address-search-wrapper">
					<AddressSearch
						addressSearchLabel="Search by Address"
						blockAndLotSearchLabel="Search by Block and Lot"
					/>
				</div>
			</div>
		</div>
	);
};

export default PropertyNotFound;
