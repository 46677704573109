import { useDispatch } from 'react-redux';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import './ResolutionRequest.css';

//data sets with a requestable document. the document name values are whitelisted by the api
export const docNameByDataSet = {
	dobViolations: 'DOB Violation Resolution',
	jobApplications: 'Job Application Drawing',
	ecbViolations: 'ECB Violation Resolution',
	fdnyViolations: 'FDNY Active Violation Order Resolution',
	fdnyPermits: 'FDNY Permit',
	hpdHousingViolations: 'HPD Housing Violation Resolution',
	repairVacateOrders: 'HPD Order to Repair/Vacate Resolution',
	citypayTickets: 'CityPay Ticket Resolution',
};

//these dataSets should show the request button even if the row isn't "open"
const showOnNonOpen = [
	'jobApplications',
	'repairVacateOrders',
	'citypayTickets',
];

function ResolutionRequest(props) {
	const dispatch = useDispatch();
	const { rowData, dataSet, dataSource } = props;

	let documentName = docNameByDataSet[dataSet];
	let extra = '';
	let text = 'Resolve';
	let modalType = MODAL_TYPES.DOCUMENT_REQUEST;

	if (!documentName) {
		return null;
	}

	if (!rowData?.isOpen && !showOnNonOpen.includes(dataSet)) {
		return "Resolved";
	}

	if (dataSet === 'jobApplications' && dataSource === 'OPEN_DATA') {
		extra = `Job Application ID ${rowData?.job__}`;
		text = 'Request';
		modalType = MODAL_TYPES.ADD_TO_CART;
	} else if (dataSet === 'jobApplications' && dataSource === 'BIS') {
		extra = `Job Application ID ${rowData?.job_number}`;
		text = 'Request';
		modalType = MODAL_TYPES.ADD_TO_CART;
	} else if (dataSet === 'dobViolations') {
		extra = `Violation Number: ${rowData?.dob_violation_number || rowData?.number || '[Violation number missing]'}`;
	} else if (dataSet === 'ecbViolations') {
		extra = `Violation Number: ${rowData?.ecb_violation_number || '[Violation number missing]'}`;
	} else if (dataSet === 'fdnyViolations') {
		extra = `Violation Number: ${rowData?.violation_num || '[Violation number missing]'}`;
	} else if (dataSet === 'fdnyPermits') {
		extra = `Account Number: ${rowData?.entityId || '[Account number missing]'}`;
	} else if (dataSet === 'hpdHousingViolations') {
		extra = `Violation Number: ${rowData?.violationid || '[Violation number missing]'}`;
	} else if (dataSet === 'repairVacateOrders') {
		extra = `Vacate Number: ${rowData?.vacate_order_number || '[Vacate number missing]'}`;
	} else if (dataSet === 'citypayTickets') {
		extra = `Ticket Number: ${rowData?.ticketNumber || '[Ticket number missing]'}`;
	} else {
		return null;
	}

	const onClick = () => dispatch(openModal(modalType, { documentName, extra }));
	return (
		<button className="btn btn-success data-table-resolution-request-button" onClick={onClick}>
			{text}
		</button>
	);
}

export default ResolutionRequest;
