// import { useState, useEffect } from 'react';
import { /*useDispatch,*/ useSelector } from 'react-redux';

import Spinner from 'components/Spinner';

import "./FirstLoadTasksCard.css";

function TaskItem({ task }) {

	//card design:
	// return (
	// 	<div className={`first-load-taks-item ${task.complete === 0 ? 'in-progress' : 'complete'}`}>
	// 		<div className="task-name">
	// 			<div className="data-set-label">{task.dataSetLabel || task.task_name}</div>
	// 			{task.dataSourceLabel ? <div className="data-source-label">({task.dataSourceLabel})</div> : null}
	// 		</div>
	// 		<div className="task-status-icon">
	// 			{task.complete === 0 ?
	// 				<Spinner /> : 
	// 				<i className="bi bi-check-lg" />
	// 			}
	// 		</div>
	// 	</div>
	// );

	// alerts design:

	let isError = !!task.errored_at;
	let isInProgress = !isError && task.complete === 0;
	let isSuccess = !isInProgress && task.completed_at;
	let color = 'secondary';
	if (isError) {
		color = 'danger';
	} else if (isSuccess) {
		color = 'success';
	}

	let icon = <Spinner size="sm" />;
	if (isError) {
		icon = <i className="bi bi-exclamation-triangle" />;
	} else if (isSuccess) {
		icon = <i className="bi bi-check-lg" />;
	}

	let prettyError = task.error;
	if (typeof task?.error === 'string') {
		prettyError = task.error.replace('\n', '')
	}

	return (
		<div className={`task-alert alert alert-${color}`}>
			<div className="task-alert-row">
				<div>
					<span className="task-alert-icon">
						{icon}
					</span>
					<span className="task-name">
						<span className="data-set-label">{task.dataSetLabel || task.task_name}</span>
						{task.dataSourceLabel ? <span className="data-source-label">({task.dataSourceLabel})</span> : null}
					</span>
				</div>
				<div className="task-times">
					<span className="task-time me-3">
						<span className="task-time-label">Started: </span><span>{task.started_at || '-'}</span>
					</span>
					{isError ? (
						<span className="task-time">
							<span className="task-time-label">Errored: </span><span>{task.errored_at || '-'}</span>
						</span>
						) : (
						<span className="task-time">
							<span className="task-time-label">Completed: </span><span>{task.completed_at || '-'}</span>
						</span>
					)}
				</div>
			</div>
			{isError && (
				<div className="fw-bold mt-2">{prettyError}</div>
			)}
		</div>
	);
}

function FirstLoadTasksCard(props) {
	// const dispatch = useDispatch();
	const {
		// isLoading,
		firstLoadTasksData,
	} = useSelector(state => ({
		// isLoading: state.firstLoadTasks.isLoading,
		firstLoadTasksData: state.firstLoadTasks?.data?.map(task => {
			let dataSetName = task.task_name;
			let dataSourceName = null;
			if (task.task_name.split('(').length === 2) {
				//some tasks don't have a data source, e.g. `Check Sanborn Maps`
				dataSetName = task.task_name?.split('(')[0];
				dataSourceName = task.task_name?.split('(')[1]?.split(')')?.[0];
			}
			return Object.assign({}, task, {
				dataSetLabel: dataSetName,
				dataSourceLabel: dataSourceName,
			})
		}) || [],
	}));

	return (
		<div className="first-load-tasks-card-container">
			<div className="first-load-tasks-card card">
				<div className="first-load-tasks-card-header card-header">
					Property Tasks
				</div>
				<div className={`first-load-tasks-card-body card-body`}>
					{firstLoadTasksData.map(task => <TaskItem key={task.task_name} task={task} />)}
				</div>
			</div>
		</div>
	);
}

export default FirstLoadTasksCard;
