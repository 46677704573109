import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import { usePageTitle } from 'utils/pageTitle';
import AdminLayout from 'pages/_rd_dashboard/AdminLayout';

import { fetchAdminPortfolios } from 'reducers/adminPortfoliosReducer';

import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';

const AdminPortfolios = (props) => {
	usePageTitle('Admin | Portfolios');
	const dispatch = useDispatch();
	let {
		portfolios,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		portfolios: state.adminPortfolios.data?.data || [],
		isLoading: state.adminPortfolios.isLoading,
		currentPage: state.adminPortfolios.data?.current_page,
		totalPages: state.adminPortfolios.data?.last_page,
	}));

	useEffect(() => {
		dispatch(fetchAdminPortfolios());
	}, [dispatch]);

	
	return (
		<AdminLayout>
			<div className="dashboard-portfolio">
				<h1>Portfolios</h1>
				{isLoading ? <Spinner /> :
					<div className="pb-4">
						<div className="dashboard-table-wrapper">
							<table className="table table-striped table-rounded">
								<thead><tr>
									<th>User</th>
									<th>Property</th>
									<th>Created At</th>
								</tr></thead>
								<tbody>
									{portfolios?.map((item) => (
										<tr key={item.id}>
											<td className="dashboard-username-cell">{item.user_slim?.name || '-'}</td>
											<td className="dashboard-table-property-address-cell">
												{item.property_name?.address ? (
													<Link to={`/property/${encodeURIComponent(item.property_name.address)}/overview`}>{item.property_name.address}</Link>
												) : '-'}
											</td>
											<td className="dashboard-date-time-cell">{item.created_at || '-'}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<Paginator
							currentPage={currentPage}
							totalPages={totalPages}
							setPage={(pageNum) => dispatch(fetchAdminPortfolios('created_at|desc', pageNum, 10))}
						/>
					</div>
				}
			</div>
		</AdminLayout>
	);
}

export default AdminPortfolios;
