
export const getPropertyDocuments = (property) => {
	// if (!property) {
	// 	return [];
	// }

	return [
		"Asbestos Reports",
		"Deeds",
		"Docket Book",
		"Energy Efficiency",
		"Equipment Use Permits",
		// "FDNY Permits (Annually Paid)",
		// "FDNY Report",
		"Health Grade",
		"Historical Images",
		"Letter of No Objection",
		"Property Card",
		"Public Assembly",
		// "Sanborn Map",
		"Sewer Map",
		"Surveys",
		// "Tax Map",
		"Temporary Use Permit",
		"Zoning Declarations & Exhibits",
	];

	//todo: move links to links

	// return {
	// 	"I-Card": { link: "https://hpdonline.hpdnyc.org/HPDonline/provide_address.aspx?p1="+ property.borough +"&p2="+ property.house_number +"&p3=" + property.street_name },
	// 	"Certificate of Occupancy": { link: "http://a810-bisweb.nyc.gov/bisweb/COsByLocationServlet?requestid=0&allbin=" + property.bin },
	// 	// "Landmark Designation Report": { prompt: true },
	// 	"Landmark Designation Report": { link: 'https://www1.nyc.gov/site/lpc/designations/designation-reports.page' },


	// 	"Letter of No Objection": { prompt: true },
	// 	"Temporary Use Permit": { prompt: true },
	// 	"Building Card": { prompt: true },
	// 	"Sanborn Map": { prompt: true },
	// 	"Historical Images": { prompt: true },
	// 	"Surveys": { prompt: true },
	// 	"Asbestos Reports": { prompt: true },
	// 	"Equipment Use Permits": { comingSoon: true },
	// 	"FDNY Permits (Annually Paid)": { comingSoon: true },
	// 	"Public Assembly": { comingSoon: true },
	// 	"Property Card": { comingSoon: true },
	// 	"Docket Book": { comingSoon: true },
	// 	"Tax Map": { comingSoon: true }
	// };
};