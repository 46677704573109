import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import { useMediaPredicate } from 'react-media-hook';
import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import { getDistrictUrl } from 'utils/zoningDistrictUtils';
import { getBuildingClassification } from "utils/buildingClassifications";
import { display, displayLotSize } from 'utils/display';
import { getQuickLinks, getDownloadLinks } from 'utils/propertyLinks'; 

import AcrisLink from 'components/externalLinks/AcrisLink';
import OutboundLink from 'components/OutboundLink';
import Tooltip from 'components/Tooltip';


import './BuildingInfoTableV2.css';
// import './BuildingInfoTable.css';


function BuildingInfoTableV2({ propertyData, zoningData }) {
	const isMobile = useMediaPredicate(MOBILE_SIZE);
	const dispatch = useDispatch();

	// let openSpecialDistModal = () => {
	// 	dispatch(openModal(MODAL_TYPES.SPECIAL_DISTRICTS))
	// };

	let zoningMapElement = display(zoningData.zonemap);
	if (propertyData?.zoning_map_number) {
		let mapNum = propertyData.zoning_map_number.toLowerCase();
		let mapUrl = `https://www1.nyc.gov/assets/planning/download/pdf/zoning/zoning-maps/map${mapNum}.pdf`;
		zoningMapElement = (
			<OutboundLink url={mapUrl}>
				{display(zoningData.zonemap)}
			</OutboundLink>
		)
	}

	let columnA = [
		(
			<div className="building-info-item">
				<span className="building-info-field">Year Built</span>
				<span className="building-info-value">{display(zoningData?.yearbuilt)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Lot Size</span>
				<span className="building-info-value">{displayLotSize(zoningData)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Lot Area</span>
				<span className="building-info-value">{display(zoningData?.lotarea?.toLocaleString())}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Gross Floor Area</span>
				<span className="building-info-value">{display(zoningData?.bldgarea?.toLocaleString())}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field"># of Buildings</span>
				<span className="building-info-value">{display(zoningData?.numbldgs)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field"># of Stories</span>
				<span className="building-info-value">{display(zoningData?.numfloors)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field"># of Units</span>
				<span className="building-info-value">{display(zoningData?.unitstotal)}</span>
			</div>
		),
	];

	let columnB = [
		(
			<div className="building-info-item">
				<span className="building-info-field">Built FAR</span>
				<span className="building-info-value">{display(zoningData.builtfar)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Commercial FAR</span>
				<span className="building-info-value">{display(zoningData.commfar)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Residential FAR</span>
				<span className="building-info-value">{display(zoningData.residfar)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Zoning district 1/2</span>
				<span className="building-info-value">
					<OutboundLink url={getDistrictUrl(zoningData.zonedist1)}>{display(zoningData.zonedist1)}</OutboundLink>/
					<OutboundLink url={getDistrictUrl(zoningData.zonedist2)}>{display(zoningData.zonedist2)}</OutboundLink>
				</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Zoning district 3/4</span>
				<span className="building-info-value">
					<OutboundLink url={getDistrictUrl(zoningData.zonedist3)}>{display(zoningData.zonedist3)}</OutboundLink>/
					<OutboundLink url={getDistrictUrl(zoningData.zonedist4)}>{display(zoningData.zonedist4)}</OutboundLink>
				</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">
					<OutboundLink url={'https://www.nyc.gov/site/planning/zoning/districts-tools/c1-c2-overlays.page'}>Commercial Overlay 1</OutboundLink>
				</span>
				<span className="building-info-value">{display(zoningData.overlay1)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">
					<OutboundLink url={'https://www.nyc.gov/site/planning/zoning/districts-tools/c1-c2-overlays.page'}>Commercial Overlay 2</OutboundLink>
				</span>
				<span className="building-info-value">{display(zoningData.overlay2)}</span>
			</div>
		),
	];


	const [isLandmarkExpanded, setIsLandmarkExpanded] = useState(false);
	const toggleLandmark = () => {
		setIsLandmarkExpanded(!isLandmarkExpanded);
	};
	let landmarkData = propertyData?.od_landmark_buildings?.[0] || {};
	let landmarkRows = [
		(
			<div className="building-info-item landmark-row landmark-toggle-row" onClick={toggleLandmark}>
				<span className="building-info-field">
					<OutboundLink url="https://www1.nyc.gov/site/lpc/designations/designation-reports.page">Landmark</OutboundLink>
					<i className={`bi bi-chevron-${isLandmarkExpanded ? 'up' : 'down'}`} />
				</span>
				<span className="building-info-value">{display(landmarkData?.hist_dist)}</span>
			</div>
		),
	];

	if (isLandmarkExpanded) {
		landmarkRows = [
			(
				<div className="building-info-item landmark-row landmark-toggle-row" onClick={toggleLandmark}>
					<span className="building-info-field">
						<OutboundLink url="https://www1.nyc.gov/site/lpc/designations/designation-reports.page">Landmark</OutboundLink>
						<i className={`bi bi-chevron-${isLandmarkExpanded ? 'up' : 'down'}`} />
					</span>
					<span className="building-info-value">{display(landmarkData?.hist_dist)}</span>
				</div>
			),
			(
				<div className="building-info-item landmark-row">
					<span className="building-info-field">
						Architect
					</span>
					<span className="building-info-value">{display(landmarkData?.arch_build)}</span>
				</div>
			),
			(
				<div className="building-info-item landmark-row">
					<span className="building-info-field">
						Primary Style
					</span>
					<span className="building-info-value">{display(landmarkData?.style_prim)}</span>
				</div>
			),
			(
				<div className="building-info-item landmark-row">
					<span className="building-info-field">
						Building Material
					</span>
					<span className="building-info-value">{display(landmarkData?.mat_prim)}</span>
				</div>
			),
			(
				<div className="building-info-item landmark-row">
					<span className="building-info-field">
						Original Use
					</span>
					<span className="building-info-value">{display(landmarkData?.build_type)}</span>
				</div>
			),

		];
	}



	let columnC = [
		
			...landmarkRows
		, (
			<Tooltip text={`${getBuildingClassification(zoningData?.bldgclass)}`}>
				<div className="building-info-item">
					<span className="building-info-field">Building Class</span>
					<span className="building-info-value">
						<OutboundLink url={`https://www.nyc.gov/assets/finance/jump/hlpbldgcode.html#${zoningData?.bldgclass?.[0]}`}>{display(zoningData?.bldgclass)}</OutboundLink>
					</span>
				</div>
			</Tooltip>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Zoning Map</span>
				<span className="building-info-value">{zoningMapElement}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">Land Use</span>
				<span className="building-info-value">{display(zoningData.landuse)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">HPD Multiple Dwelling</span>
				<span className="building-info-value">{display(propertyData?.hpd_multiple_dwelling)}</span>
			</div>
		), (

			<div className="building-info-item">
				<span className="building-info-field">SRO Restricted</span>
				<span className="building-info-value">{display(propertyData?.sro_restricted)}</span>
			</div>
		), (
			<div className="building-info-item">
				<span className="building-info-field">OER</span>
				<span className="building-info-value">{display(propertyData?.environmental_restrictions)}</span>
			</div>
		)
	];

	// //these are items that were removed in the 3 column design
	// let unusedItems = [
	// 	(
	// 		<div className="building-info-item">
	// 			<span className="building-info-field">Facility FAR</span>
	// 			<span className="building-info-value">{display(zoningData.sro_restricted)}</span>
	// 		</div>
	// 	),
	// 	(
	// 		<div className="building-info-item">
	// 			<span className="building-info-field">
	// 				<button onClick={openSpecialDistModal} className="btn-hyperlink btn btn-sm">Special District 1/2</button>
	// 			</span>
	// 			<span className="building-info-value">{display(zoningData.spdist1)}/{display(zoningData.spdist2)}</span>
	// 		</div>
	// 	), (
	// 		<div className="building-info-item">
	// 			<span className="building-info-field">
	// 				<button onClick={openSpecialDistModal} className="btn-hyperlink btn btn-sm">Special District 3</button>
	// 			</span>
	// 			<span className="building-info-value">{display(zoningData.spdist3)}</span>
	// 		</div>
	// 	), (
	// 		<div className="building-info-item">
	// 			<span className="building-info-field">BIN</span>
	// 			<span className="building-info-value">
	// 				<OutboundLink url={`http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=${propertyData?.borough}&houseno=${propertyData?.house_number}&street=${propertyData?.street_name}`}>
	// 					{display(propertyData?.bin)}
	// 				</OutboundLink>
	// 			</span>
	// 		</div>
	// 	), (
	// 		<div className="building-info-item">
	// 			<span className="building-info-field">Block/Lot</span>
	// 			<span className="building-info-value">{display(zoningData?.block)}/{display(zoningData?.lot)}</span>
	// 		</div>
	// 	), (
	// 		<div className="building-info-item">
	// 			<span className="building-info-field"># of Dwelling Units</span>
	// 			<span className="building-info-value">{display(propertyData?.num_dwelling_units)}</span>
	// 		</div>
	// 	),
	// ];


	const hpd_building_id = propertyData?.hpd_building_id;
	const dobBisLink = getQuickLinks(propertyData)?.['DOB BIS'];
	const headerButtons = (
		<div className="property-link-buttons">
			<OutboundLink text="LPC" className={`btn btn-primary btn-sm`} url="https://www.nyc.gov/site/lpc/index.page" noIcon />
			{hpd_building_id && <OutboundLink text="HPD" className={`btn btn-primary btn-sm`} url={`https://hpdonline.nyc.gov/hpdonline/building/${hpd_building_id}/overview`} noIcon />}
			{propertyData && <AcrisLink buttonClass="btn btn-primary btn-sm" label="ACRIS" />}
			{dobBisLink && <OutboundLink text="DOB BIS" className={`btn btn-primary btn-sm`} url={dobBisLink} noIcon />}
			<OutboundLink text="DOB NOW" className={`btn btn-primary btn-sm`} url="https://a810-dobnow.nyc.gov/publish/Index.html#!/" noIcon />
		</div>
	);

	const openFdnyReportModal = () => dispatch(openModal(MODAL_TYPES.FDNY_REPORT));

	const cooLink = getDownloadLinks(propertyData)?.['Certificate of Occupancy'];
	const hpdHistoricLink = `https://hpdonline.nyc.gov/hpdonline/building/${hpd_building_id}/overview`;
	const lat = propertyData?.lat;
	const longitude = propertyData?.['long'];
	const landmarkLink = `https://nyclpc.maps.arcgis.com/apps/webappviewer/index.html?id=93a88691cace4067828b1eede432022b&marker=${longitude},${lat},,,,&markertemplate={"title":"","longitude":${longitude},"latitude":${lat},"isIncludeShareUrl":true}&level=18`;
	const fileButtons = (
		<div className="property-file-icon-links">
			{cooLink && 
				<Tooltip text={`Certificate of Occupancy`}>
					<OutboundLink className={`file-icon-button`} noIcon url={cooLink}>
						<img className="" src={`/file-image-nyc-buildings.png`} alt={`nyc-buildings`} />
					</OutboundLink>
				</Tooltip>
			}
			<Tooltip text={`FDNY`}>
				<button className="btn-hyperlink text-left" onClick={openFdnyReportModal}>
					<img className="" src={`/file-image-fdny.png`} alt={`fdny`} />
				</button>
{/*				<OutboundLink className={`file-icon-button`} noIcon url={`https://fires.fdnycloud.org/CitizenAccess/Report/ReportParameter.aspx?module=&reportID=1423&reportType=LINK_REPORT_LIST`}>
					<img className="" src={`/file-image-fdny.png`} alt={`fdny`} />
				</OutboundLink>*/}
			</Tooltip>
			{hpd_building_id && 
				<Tooltip text={`Historical Image Cards`}>
					<OutboundLink className={`file-icon-button`} noIcon url={hpdHistoricLink}>
						<img className="" src={`/file-image-hpd.png`} alt={`hpd`} />
					</OutboundLink>
				</Tooltip>
			}
			{landmarkLink && 
				<Tooltip text={`Landmarks Preservation Commission`}>
					<OutboundLink className={`file-icon-button`} noIcon url={landmarkLink}>
						<img className="" src={`/file-image-landmarks.png`} alt={`landmarks`} />
					</OutboundLink>
				</Tooltip>
			}
		</div>
	);

	return (
		<div className="rd-building-info">
			<div className="building-info-flex-wrapper">
				<div>
					<div className="mb-2 building-info-card-header">
						<div>
							<h2 className="building-info-card-title">Property Information</h2>
							{zoningData?.ownername && <div className="owner-name-subheader">Owner: <strong>{zoningData?.ownername}</strong></div>}
						</div>
						{!isMobile && headerButtons}
					</div>
					<div className="rd-building-info-col-container">
						<div className="building-info-col">
							{columnA.map((item, i) => <div className="building-info-item-wrapper" key={i}>{item}</div>)}
						</div>
						<div className="building-info-col">
							{columnB.map((item, i) => <div className="building-info-item-wrapper" key={i}>{item}</div>)}
						</div>
						<div className="building-info-col">
							{columnC.map((item, i) => <div className="building-info-item-wrapper" key={i}>{item}</div>)}
						</div>
					</div>
				</div>
				{fileButtons}
			</div>
		</div>
	);
}

export default BuildingInfoTableV2;

