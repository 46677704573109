import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestDocumentByEmail, clearDocumentRequestError } from 'reducers/documentRequestReducer';
import { isValidEmail } from 'utils/validators';
import { docNameByDataSet } from 'formatters/ResolutionRequest';

import Alert from 'view/components/Alert';
import Spinner from 'view/components/Spinner';
import StandardModal from 'modals/StandardModal';
import './DocumentRequestModal.css';

// export const docNameByDataSet = {
// 	dobViolations: 'DOB Violation Resolution',
// 	jobApplications: 'Job Application Drawing',
// 	ecbViolations: 'ECB Violation Resolution',
// 	fdnyViolations: 'FDNY Active Violation Order Resolution',
// 	fdnyPermits: 'FDNY Permit',
// 	hpdHousingViolations: 'HPD Housing Violation Resolution',
// 	repairVacateOrders: 'HPD Order to Repair/Vacate Resolution',
// 	citypayTickets: 'CityPay Ticket Resolution',
// };

const DocumentRequestModal = ({ options }) => {
	const dispatch = useDispatch();
	const { documentName, extra } = options;
	const {
		address,
		propertyId,
		// userId,
		isSubmitting,
		hasError,
		userData,
	} = useSelector(state => ({
		address: state.property.propertyData?.address,
		propertyId: state.property.propertyData?.id,
		// userId: state.user.userData?.id,
		isSubmitting: state.documentRequest.isSubmitting,
		hasError: state.documentRequest.hasError,
		userData: state.user.userData,
	}));

	let hireUsDataSets = [
		'dobViolations',
		'ecbViolations',
		'fdnyViolations',
		'fdnyPermits',
		'hpdHousingViolations',
		'repairVacateOrders',
		'citypayTickets',
	];

	let isHireUs = false;
	if (hireUsDataSets.map(dataSet => docNameByDataSet[dataSet]).includes(options?.documentName)) {
		isHireUs = true;
	}

	useEffect(() => {
		if (userData?.email) {
			setEmail(userData.email);
			setIsEmailValid(isValidEmail(userData.email));
		}
		if (userData?.name) {
			setName(userData.name);
		}
		if (userData?.phone) {
			setPhone(userData.phone);
		}
	}, [userData]);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => { // clear old error on initial load
		dispatch(clearDocumentRequestError());
	}, []);

	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [isSuccess, setIsSuccess] = useState(false);
	const [sentToEmail, setSentToEmail] = useState('');

	const onChangeEmail = (emailString) => {
		setEmail(emailString);
		setIsEmailValid(isValidEmail(emailString));
	}

	const onSubmit = () => {
		if (isValidEmail) {
			setIsSuccess(false);
			setSentToEmail(email)
			let documentInfo = { propertyId, documentName, email, phone, name };
			if (extra) {
				documentInfo.extra = extra;
			}
			dispatch(requestDocumentByEmail(documentInfo)).then((res) => {
				if (res?.type === "REQUEST_DOCUMENT_SUCCESS") {
					setIsSuccess(true);	
				}
			});
		}
	}

	const reqForm = (
		<div>
			<div className="form-group">
				<label>{isHireUs ? 'Request' : 'Document'}</label>
				<input type="text" className="form-control" value={documentName} disabled />
			</div>
			<div className="form-group">
				<label>Address</label>
				<input type="text" className="form-control" value={address} disabled />
			</div>
			<div className="form-group">
				<label>Email Address (Required)</label>
				<input
					type="email"
					placeholder="Email"
					className={`form-control email-input ${isEmailValid ? 'valid' : 'invalid'}`}
					required
					value={email}
					onChange={(ev) => onChangeEmail(ev.target.value)}
					autoFocus
				/>
			</div>
			<div className="form-group">
				<label>Name (Optional)</label>
				<input
					type="text"
					className="form-control"
					value={name}
					onChange={ev => setName(ev.target.value)}
					placeholder="Your name"
				/>
			</div>
			<div className="form-group">
				<label>Phone Number (Optional)</label>
				<input
					type="text"
					className="form-control"
					value={phone}
					onChange={ev => setPhone(ev.target.value)}
					placeholder="(555)-555-5555"
				/>
			</div>
		</div>
	);

	const errorAlert = (
		<Alert color="danger">
			<p><strong>Oops, looks like something went wrong</strong></p>
			<div>Your document request has not been received.</div> 
			<div>Please try again or contact violerts via email if this problem continues.</div>
		</Alert>
	);

	const successAlert = (
		<Alert color="success">
			<p><strong>Document request sent!</strong></p>
			<div>{`A request for ${documentName} document(s) for ${address} has been sent to Violerts.`}</div> 
			<div>{`You should receive a follow up by email at ${sentToEmail} when your request has been processed.`}</div>
		</Alert>
	);

	const docDisclaimer = (
		<div>
{/*					<p>By submitting this form, Violerts will be notified of your request for the following document(s):</p>
			<ul><li><strong>{documentName}</strong> - {address}</li></ul>*/}
			<div>Violerts will contact you by email to deliver the requested document(s).</div>
			<div>Each document request may have a fee, which will be disclosed by email upon request.</div>
		</div>
	);

	const hireUsDescription = (
		<div className="mb-3">
			We will contact you via email with next steps for resolving this issue.
		</div>
	)

	return (
		<StandardModal
			title={isHireUs ? 'Hire Us' : 'Document Request'}
			size="lg"
			closeOnOutsideClick
			onConfirm={onSubmit}
			disableConfirm={!email || !isEmailValid || isSubmitting}
			disableClose={isSubmitting}
			confirmText="Submit Request"
			closeLabel="Cancel"
		>
			<div className="docuemnt-request-modal-content">
				{isSubmitting && <Spinner />}
				{hasError && errorAlert}
				{isSuccess && successAlert}
				{isHireUs && hireUsDescription}
				{reqForm}
				{!isHireUs && docDisclaimer}
			</div>
		</StandardModal>
	);
};

export default DocumentRequestModal;
