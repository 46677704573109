import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import dataTables from 'view/dataTables';
import { dataConfig } from 'data/dataConfig';
import { OPEN_DATA } from 'data/dataSourceConstants'

import { getReducerName, DATA_SOURCES } from 'data/dataSourceConstants';

import Badge from 'components/Badge';
import VerticalTabCard from 'pages/_rd_overview/VerticalTabCard';
import DataSetsList, { buildDataSetItem } from 'pages/_rd_overview/dataSetsCard/DataSetsList';
import DataTableContent from 'pages/_rd_overview/dataSetsCard/DataTableContent';
import Logo from 'components/appHeader/Logo';


import './DataSetsCardVertical.css';


function countViolations(propertyData) {
	// counts[category] = sum of open issues in that category
	// e.g. counts = { violations: 143, infrastructure: 22, certifications: 4 }
	let counts = {}; 
	Object.keys(dataTables).forEach((dataSet) => {
		let config = dataTables[dataSet];

		//data source sub-loop
		DATA_SOURCES.forEach((dataSource) => {
			if (config[dataSource]) {
				let category = config.dataCategory;
				let openIssueCountField = dataConfig[dataSet]?.openIssueCountFields[dataSource];
				let openIssueCount = parseInt(propertyData?.[openIssueCountField]) || 0;
				if (counts[category]) {
					counts[category] += openIssueCount;
				} else {
					counts[category] = openIssueCount;
				}
			}
		});
	});
	return counts;
}

function DataSetsCardVertical() {
	const [content, setContent] = useState('violation');
	const [selectedDataSet, setSelectedDataSet] = useState(null);
	let propertyData = useSelector(state => state.property.propertyData || {});


	let categoryViolationCounts = countViolations(propertyData);

	const buttons = [
		{
			// label: `Violations`,
			children: (<span>Violations <Badge count={categoryViolationCounts['violation']} /></span>),
			onClick: () => setContent('violation'),
			isSelected: content === 'violation',
		},
		{
			// label: `Infrastructure`,
			children: (<span>Infrastructure <Badge count={categoryViolationCounts['infrastructure']} /></span>),
			onClick: () => setContent('infrastructure'),
			isSelected: content === 'infrastructure',
		},
		{
			// label: `Certifications`,
			children: (<span>Certifications <Badge count={categoryViolationCounts['certification']} /></span>),
			onClick: () => setContent('certification'),
			isSelected: content === 'certification',
		},
	];



	const open = (item) => {
		let options = {
			dataSet: item.dataSet,
			reducerName: getReducerName(item.dataConfig, item.dataSource),
			dataSource: item.dataSource,
			title: item.config.title,
			columns: item.config[item.dataSource].columns,
		};

		setSelectedDataSet(options);
	};

	useEffect(() => {
		//set initial selected dataset to ecb violations open data
		if (propertyData?.id) {
			setContent('violation');
			open(buildDataSetItem('ecbViolations', OPEN_DATA, propertyData));	
		}
	}, [propertyData])

	function getDataSetList(contentName) {
		if (content === 'violation') {
			return <DataSetsList category={'violation'} onSelectDataSet={open} selectedDataSet={selectedDataSet} />;
		} else if (content === 'infrastructure') {
			return <DataSetsList category={'infrastructure'} onSelectDataSet={open} selectedDataSet={selectedDataSet} />;
		} else if (content === 'certification') {
			return <DataSetsList category={'certification'} onSelectDataSet={open} selectedDataSet={selectedDataSet} />;
		} 
	}

	const blankDataSet = (
		<div className="blank d-flex flex-column align-items-center justify-content-center w-100 h-100">
			<Logo/>
			<p>Select a Dataset to view data</p>
		</div>
	);

	return (
		<div className="rd-overview-data-sets-card-vertical">
			<VerticalTabCard buttons={buttons}>
				<div className="rd-overview-data-sets-card-vertical-content">
					<div className="rd-overview-data-sets-card-vertical-list-wrapper">
						{getDataSetList(content)}
					</div>
					<div className="rd-overview-data-sets-card-vertical-table-wrapper">
						{selectedDataSet ? 
							<DataTableContent
								dataSet={selectedDataSet.dataSet}
								reducerName={selectedDataSet.reducerName}
								dataSource={selectedDataSet.dataSource}
								title={selectedDataSet.title}
								columns={selectedDataSet.columns}
							/>
						: blankDataSet}
					</div>
				</div>
			</VerticalTabCard>
		</div>
	);
}

export default DataSetsCardVertical;
