import { VENDOR_LINKS } from 'utils/propertyLinks';

import OutboundLink from 'components/OutboundLink';

// import { useMediaPredicate } from 'react-media-hook';
// import { MOBILE_SIZE } from 'utils/mediaQueryUtils';

import './VendorsCard.css';

function VendorsCard() {
	const links = [
		{
			label: 'Architecture',
			imageFile: 'aapc-monogram',
			className: 'aapc',
			url: VENDOR_LINKS.AAPC,
			// colorPrimary: '#ffdc32',
		},
		{
			label: 'Construction',
			imageFile: 'arkco-monogram',
			className: 'arkco',
			url: VENDOR_LINKS.ARKCO,
			// colorPrimary: '#f79421',
		},
		{
			label: 'Title Search',
			imageFile: 'az-title-monogram',
			className: 'az-title',
			url: VENDOR_LINKS.AzTitle,
			// colorPrimary: '#29373b',
		},
		{
			label: 'Inspections',
			imageFile: 'azark-monogram',
			className: 'azark',
			url: VENDOR_LINKS.AZARK,
			// colorPrimary: '#d5e1b5',
		},
		{
			label: 'Expediting',
			imageFile: 'bvs-monogram',
			className: 'bvs',
			url: VENDOR_LINKS.BVS,
			// colorPrimary: '#aa182c',
		},
		{
			label: 'Printing',
			imageFile: 'vps-monogram',
			className: 'vps',
			url: VENDOR_LINKS.VPS,
			// colorPrimary: '#e91172',
		},

	]

	return (
		<div className="overview-card overview-card-small vendors-card">
			<div className="overview-card-content">
				<div className="fw-bold">Vendors</div>
				<div className="vendor-links">
					{links.map(link => (
							<OutboundLink className={`vendor-link ${link.className}`} noIcon url={link.url} key={link.url}>
								<span>{link.label}</span>
								<img className="" src={`/${link.imageFile}.png`} alt={`${link.imageFile}`} />
							</OutboundLink>
						
					))}
				</div>

			</div>
		</div>
	);
}

export default VendorsCard;
