import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toggleCollapseDashboardNav } from 'reducers/uiStateReducer';
import DashboardNav from 'pages/_rd_dashboard/DashboardNav';
 
import './DashboardLayout.css';

const DashboardLayout = ({ children }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	let {
		isLoggedIn,
		isLoading,
		isUpdating,
		isCollapsed,
	} = useSelector(state => ({
		isLoggedIn: !!state.user.userData?.id,
		isLoading: state.user.isLoading,
		isUpdating: state.user.isUpdating,
		isCollapsed: state.uiState.isDashboardNavCollapsed,
	}));


	useEffect(() => {
		if (!isLoggedIn && !isLoading && !isUpdating) {
			history.replace('/sign-in');
		}
	}, [isLoggedIn, isLoading, isUpdating, history]);


	const toggleCollapse = () => {
		dispatch(toggleCollapseDashboardNav());
	};

	return (
		<div className="rd-dashboard">
			<DashboardNav isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
			<div className={`rd-dashboard-content-wrapper ${(isCollapsed) ? 'is-collapsed' : ''}`}>
				<div className="rd-dashboard-content">
					{children}
				</div>
			</div>
		</div>
	);	


}

export default DashboardLayout;
